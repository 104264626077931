import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Gallery from 'react-photo-gallery';

import CloudImg from 'react-cloudimage-responsive';

import reverse from 'lodash/reverse';
import BaseSection from './BaseSection';

import Images from '../utils/Images';
import './album.scss';
import CloudImgProvider from '../components/CloudImgProvider';

let AlbumModal;
// Only import AlbumModal on client versions (IE detection and react-images breaks SSR)
if (process.env.REACT_APP_BUILD_TARGET !== 'server') {
  // eslint-disable-next-line global-require
  AlbumModal = require('../components/AlbumModal').default;
}

let cachedPhotos = null;

const imgWithClick = { cursor: 'pointer' };

// BasicImg is used for the editor, lazy CloudImg component doesn't work there
const BasicImg = ({ src }) => <img alt="" src={Images.maxWidth(src, 500)} loading="lazy" />;

BasicImg.propTypes = {
  src: PropTypes.string.isRequired,
};

/**
 * DEPRECATED
 */
export default class AlbumSection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      photos: cachedPhotos,
      viewerIsOpen: false,
      currentImage: -1,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    this.fetchPhotos();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getPhotos() {
    const { isPreview } = this.props;
    const { photos } = this.state;

    if (!photos) return photos;

    if (isPreview) {
      // Limit preview size
      return photos.slice(0, 20);
    }
    return photos;
  }

  closeLightBox = () => this.setState({ viewerIsOpen: false });

  fetchPhotos = async () => {
    const { eventId } = this.props;

    // TODO: secure with token ? Inject token from server ?
    const url = `https://app.appcraft.events/api/${eventId}/images`;
    const res = await fetch(url);
    const json = await res.json();
    if (!this._isMounted) return;
    const photos = reverse(
      json.map((photo) => {
        const image = {
          width: photo.width,
          height: photo.height,
          uri: `https://app.appcraft.events/images/${eventId}/images/${photo.uuid}.jpg`,
        };
        return {
          key: photo.uuid,
          src: image.uri, // Images.maxWidth(image, 2000),
          screenSrc: Images.maxWidth(image, 1600),
          cdn: Images.cdn(image),
          original: image.uri,
          srcSet: [200, 300, 500, 800, 1024, 1600].map(
            (size) => `${Images.maxWidth(image, size)} ${size}w`,
          ),
          width: image.width,
          height: image.height,
        };
      }),
    );
    cachedPhotos = photos;
    this.setState({ photos });
  };

  openLightBox = (e, { index }) => {
    this.setState({
      viewerIsOpen: true,
      currentImage: index,
    });
  };

  renderImage = ({ index, onClick, photo, margin, direction, top, left }) => {
    const imgStyle = { margin, display: 'block', width: photo.width, height: photo.height };
    if (direction === 'column') {
      imgStyle.position = 'absolute';
      imgStyle.left = left;
      imgStyle.top = top;
    }
    // eslint-disable-next-line no-restricted-globals
    const ImgComponent = typeof window !== 'undefined' && window.editorIframe ? BasicImg : CloudImg;
    return (
      <div
        key={index}
        style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
        onClick={(e) => onClick(e, { index, photo })}
      >
        <ImgComponent
          src={photo.original}
          width={photo.width}
          height={photo.height}
          ratio={photo.width / photo.height}
        />
      </div>
    );
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { margin, ...props } = this.props;
    const { viewerIsOpen, currentImage } = this.state;

    const photos = this.getPhotos();
    return (
      <CloudImgProvider>
        <BaseSection {...props} block={this.props}>
          {photos && (
            <Gallery photos={photos} renderImage={this.renderImage} onClick={this.openLightBox} />
          )}
          {viewerIsOpen && (
            <AlbumModal photos={photos} currentImage={currentImage} onClose={this.closeLightBox} />
          )}
        </BaseSection>
      </CloudImgProvider>
    );
  }
}

AlbumSection.defaultProps = {
  isPreview: false,
  margin: undefined,
};

AlbumSection.propTypes = {
  eventId: PropTypes.string.isRequired,
  isPreview: PropTypes.bool,
  margin: PropTypes.number,
};
