import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withComponents } from './Context';
import { figureImageProps } from '../utils/propTypes';

let PhotoGalleryModal;
// Only import AlbumModal on client versions (IE detection and react-images breaks SSR)
if (process.env.REACT_APP_BUILD_TARGET !== 'server') {
  // eslint-disable-next-line global-require
  PhotoGalleryModal = require('./PhotoGalleryModal').default;
}

class RawGallery extends PureComponent {
  render() {
    const { image, label, onClick, showLabels, components, imageProps } = this.props;
    const { Image, RichText } = components;
    return (
      <React.Fragment>
        <Image
          src={image}
          isGallery
          onClick={onClick}
          maxWidth={500}
          square
          loading="lazy"
          className="RawGallery__image"
          imageProps={imageProps}
        />
        {showLabels && (
          <div
            className="RawGallery__text"
            style={{ fontWeight: 'bold', fontSize: '1em', marginTop: 5 }}
          >
            <RichText html={label} name="label" placeholder="Add label" />
          </div>
        )}
      </React.Fragment>
    );
  }
}

RawGallery.defaultProps = {
  image: '',
  imageProps: undefined,
  label: '',
  onClick: '',
  showLabels: false,
};

RawGallery.propTypes = {
  components: PropTypes.object.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imageProps: figureImageProps,
  label: PropTypes.string,
  onClick: PropTypes.func,
  showLabels: PropTypes.bool,
};

const GalleryItem = withComponents(RawGallery);

class PhotoGallery extends PureComponent {
  state = {
    current: 0,
    isOpen: false,
  };

  closeLightbox = () => {
    this.setState({ isOpen: false });
  };

  handleClickImage = (index) => {
    this.setState({ current: index, isOpen: true });
  };

  renderItem = (item, index) => {
    const { showLabels, imageProps } = this.props;
    return (
      <GalleryItem
        {...item}
        imageProps={imageProps}
        showLabels={showLabels}
        onClick={() => this.handleClickImage(index)}
      />
    );
  };

  render() {
    const { images, gridSize, margin, components, imageProps, mobileGridSize } = this.props;
    const { current, isOpen } = this.state;
    const { Grid } = components;
    return (
      <div>
        <Grid
          currentImage={current}
          isGallery
          margin={margin}
          items={images}
          name="itemList.items"
          gridSize={gridSize}
          tabletGridSize={Math.round(gridSize / 2)}
          mobileGridSize={mobileGridSize}
          render={this.renderItem}
        />
        {isOpen ? (
          <PhotoGalleryModal
            currentIndex={current}
            images={images}
            onClose={this.closeLightbox}
            imageProps={imageProps}
          />
        ) : undefined}
      </div>
    );
  }
}

PhotoGallery.defaultProps = {
  gridSize: 4,
  imageProps: undefined,
  images: [],
  showLabels: true,
  margin: 'normal',
};

PhotoGallery.propTypes = {
  components: PropTypes.object.isRequired,
  gridSize: PropTypes.number,
  imageProps: figureImageProps,
  images: PropTypes.array,
  showLabels: PropTypes.bool,
  margin: PropTypes.string,
};

export default withComponents(PhotoGallery);
