import React from 'react';
import { CloudimageProvider } from 'react-cloudimage-responsive';

export const cloudimageConfig = {
  token: 'avfuefcxen',
  lazyLoadOffset: 800,
};

class UnloadableCloudimageProvider extends CloudimageProvider {
  componentWillUnmount() {
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('resize', this.updateDimensions);
  }
}

// eslint-disable-next-line react/prop-types
const CloudImgProvider = ({ children }) => (
  <UnloadableCloudimageProvider config={cloudimageConfig}>{children}</UnloadableCloudimageProvider>
);

export default CloudImgProvider;
