import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseSection from './BaseSection';
import PhotoGallery from '../components/PhotoGallery';
import { figureImageProps } from '../utils/propTypes';

export default class GallerySection extends PureComponent {
  render() {
    const { itemList, showLabels, margin, gridSize, mobileGridSize, imageProps, ...props } =
      this.props;
    return (
      <BaseSection {...props} block={this.props}>
        <PhotoGallery
          imageProps={imageProps}
          images={itemList.items}
          gridSize={gridSize}
          showLabels={showLabels}
          margin={margin}
          mobileGridSize={mobileGridSize || 1}
        />
      </BaseSection>
    );
  }
}

GallerySection.defaultProps = {
  gridSize: 4,
  imageProps: undefined,
  itemList: {},
  margin: 'normal',
  showLabels: true,
};

GallerySection.propTypes = {
  gridSize: PropTypes.number,
  imageProps: figureImageProps,
  itemList: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.object) }),
  margin: PropTypes.oneOf(['none', 'normal', 'large']),
  showLabels: PropTypes.bool,
};
