import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Carousel, { Modal, ModalGateway } from 'react-images';
import get from 'lodash/get';
import Images from '../utils/Images';
import { figureImageProps } from '../utils/propTypes';

class PhotoGalleryModal extends PureComponent {
  render() {
    const { currentIndex, images, imageProps, onClose } = this.props;
    const imageOptions = { type: get(imageProps, 'type', 'original') };
    const lightboxImages = images
      .filter((i) => i.image)
      .map((i) => {
        const { image, label } = i;
        return {
          src: Images.maxWidth(image, 2000, imageOptions),
          caption: label,
          thumbnail: Images.square(image, 100, imageOptions),
        };
      });
    return (
      <ModalGateway>
        <Modal onClose={onClose}>
          <Carousel currentIndex={currentIndex} views={lightboxImages} />
        </Modal>
      </ModalGateway>
    );
  }
}

PhotoGalleryModal.defaultProps = {
  imageProps: undefined,
};

PhotoGalleryModal.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  imageProps: figureImageProps,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object,
      label: PropTypes.string,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PhotoGalleryModal;
